<div class="card">
    <div class="card-header">
        <h1 class="h3 mb-2 text-gray-800">WhiteList IP</h1>
    </div>
    <div class="card-body">
        <form (ngSubmit)="SaveUser()" [formGroup]="ipForm" >
            <div class="form-row">
                <div class="col">
                    <label>IP Address</label>
                    <input required type="text" formControlName="ipAddress" class="form-control" placeholder="Ip Address" autocomplete="off">
                    <div *ngIf="ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)">
                        <div *ngIf="userName.errors?.required">Ip Address is required.</div>
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <button type="submit" [disabled]="ipAddress.invalid" class="btn btn-primary btn-user btn-block m-1 col-2 ml-auto">
                    {{'Submit'}}
                </button>
            </div>
        </form>
        <br/>
              <ag-grid-angular 
        [rowData]="rowData" [columnDefs]="colDefs" class="ag-theme-quartz" style="height: 500px;" [defaultColDef]="gridOptions" [gridOptions]="gridOptions" />
    </div>
</div>
<notifier-container></notifier-container>