  <div id="wrapper">
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/" >
            <div class="sidebar-brand-icon rotate-n-15">
               <img width="60" src="/img/logo.png" />
            </div>
            <div class="sidebar-brand-text mx-3">Stock Management</div>
        </a>
        <hr class="sidebar-divider my-0">
        <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/" >
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Dashboard</span></a>
        </li>  -->
        <li class="nav-item" *ngIf="isShowTab('Stock')">
            <a class="nav-link" routerLink="/stock" >
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Stock</span></a>
        </li> 
        <li class="nav-item">
            <a class="nav-link" routerLink="/racknlevel" *ngIf="isShowTab('RacknLevel')">
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Rack & Level</span></a>
        </li> 
        <li class="nav-item">
            <a class="nav-link" routerLink="/checkin" *ngIf="isShowTab('CheckIn')">
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Stock Check In</span></a>
        </li> 
        <li class="nav-item" *ngIf="isShowTab('CheckOut')">
            <a class="nav-link" routerLink="/checkout" >
                <i class="fas fa-fw fa-table"></i>
                <span>Stock Check Out</span></a>
        </li>
        <li class="nav-item" *ngIf="isShowTab('Audit')">
            <a class="nav-link" routerLink="/audit" >
                <i class="fas fa-fw fa-table"></i>
                <span>Stock Audit</span></a>
        </li>
        <li class="nav-item" *ngIf="isShowTab('User')">
            <a class="nav-link" routerLink="/user" >
                <i class="fas fa-fw fa-table"></i>
                <span>User Management</span></a>
        </li>
        <li class="nav-item" *ngIf="isShowTab('Ip')">
            <a class="nav-link" routerLink="/ip" >
                <i class="fas fa-fw fa-table"></i>
                <span>White List Ip</span></a>
        </li>
        <hr class="sidebar-divider d-none d-md-block">
    </ul>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                    <i class="fa fa-bars"></i>
                </button>
                <ul class="navbar-nav ml-auto">

                    <div class="topbar-divider d-none d-sm-block"></div>
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{userProfile?.userName}}</span>
                            <img class="img-profile rounded-circle"
                                src="img/undraw_profile.svg">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <a class="dropdown-item" href="#" (click)="logout()" >
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>

                </ul>

            </nav>
            <div class="container-fluid">
                <router-outlet />
            </div>
        </div>
    </div>
</div>

