import { Component } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { StockService } from '../service/stock.service';
import { ViewButtonRendererComponentComponent } from '../ag-grid/view-button-renderer-component/view-button-renderer-component.component';
import { FormsModule } from '@angular/forms';
import { SearchPipe } from '../pipe/SearchPipe';
import moment from 'moment';
import { NotifierModule, NotifierService } from 'angular-notifier';
declare var bootstrap: any;
@Component({
  selector: 'app-stock',
  standalone: true,
  imports: [AgGridAngular, FormsModule, NotifierModule],
  templateUrl: './stock.component.html',
  styleUrl: './stock.component.css'
})
export class StockComponent {
  searchText: string = "";
  searchDetailText: string = "";
  columnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },

    { headerName: 'Available Stock', field: 'availableStock' },
    {
      headerName: 'Min. Quantity Alert', field: 'minQuantityAlert', editable: true,
      cellEditor: 'agNumberCellEditor',
      hide: !this.isShowSpecialAllow(),
      cellEditorParams: {
        precision: 0,
        showStepperButtons: true
      }
    },
    {
      headerName: 'Is Product Active', field: 'isActive',
      hide: !this.isShowSpecialAllow(),
      valueGetter: (params: any) => {
        return params.data.isActive == true ? 'Yes' : 'No';
      }
    },
    {
      headerName: 'Actions',
      colId: "action",
      cellRenderer: ViewButtonRendererComponentComponent,
      width: 150,
      cellRendererParams: {
        onView: this.onView.bind(this),
        onActivate: this.onActivateModel.bind(this),
        hide: !this.isShowSpecialAllow()
      }
    }

  ];
  futureColumnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },

    { headerName: 'Transit Stock', field: 'availableStock' },
    {
      headerName: 'Min. Quantity Alert', field: 'minQuantityAlert', editable: true,
      cellEditor: 'agNumberCellEditor',
      hide: !this.isShowSpecialAllow(),
      cellEditorParams: {
        precision: 0,
        showStepperButtons: true
      }
    },
    {
      headerName: 'Is Product Active', field: 'isActive',
      hide: !this.isShowSpecialAllow(),
      valueGetter: (params: any) => {
        return params.data.isActive == true ? 'Yes' : 'No';
      }
    }
  ];

  min_slowColumnDefs = [
    { headerName: 'Product Name', field: 'name', editable: false, width: 300 },
    { headerName: 'Available Stock', field: 'availableStock' },
    { headerName: 'Min. Quantity Alert', field: 'minQuantityAlert'},
    { headerName: 'Last Updated Date', field: 'lastUpdateDate' , valueFormatter:this.dateFormatter}
  ];

  constructor(private stockService: StockService, private notifier: NotifierService
  ) {
  
    if (!this.isViewAllowed()) {
      this.columnDefs = this.columnDefs.filter(x => x.headerName != 'Actions');
    }
  }

  rowData = [
  ];
  rowFilteredData: any[] = [];
  rowDetailData = [];
  rowFutureFilteredData: any[] = [];
  rowFutureData = [];
  minRowData: any = [];
  minRowFilteredData: any = [];
  slowRowData: any = [];
  slowRowFilteredData: any = [];
  rowDetailFilteredData: any[] = [];
  gridApi: any;
  minGridApi: any;
  slowGridApi: any;
  searchFutureText="";
  detailGridApi: any;
  futureGridApi: any;
  searchMinText=""
  searchSlowText=""
  detailColumnDefs = [
    { headerName: 'Product Name', field: 'productName', editable: false, width: 300 },
    { headerName: 'Grade', field: 'grade', editable: false },
    { headerName: 'Rack', field: 'rack', editable: false },
    { headerName: 'Level', field: 'level', editable: false },
    {
      headerName: 'Total Box x Qty per box', field: 'totalBox', width: 250
    },
    {
      headerName: 'Quantity Loose', field: 'quantityLoss', width: 150
    },
    {
      headerName: 'Sold Quantity', field: 'soldQuantity', width: 150,
      cellStyle: (params: any) => {
        if (params.value < 0) {
          return { color: 'red' };
        }
        return null;
      }
    },
    { headerName: 'Net Quantity', field: 'netQuantity', editable: false },

  ];
  defaultColDef = {
    sortable: true, filter: true
  };

  dateFormatter(params: any) {
    return moment(params.value).format('DD-MM-YYYY');
  }

  gridOptions = {
    columnDefs: this.columnDefs,
    rowData: this.rowFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.gridApi = params.api;
      this.getStocks();
    }
  }

  gridOptionsMinimumStock = {
    columnDefs: this.min_slowColumnDefs,
    rowData: this.minRowFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.minGridApi = params.api;
    }
  }

  gridOptionsShowMoving = {
    columnDefs: this.min_slowColumnDefs,
    rowData: this.slowRowFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.slowGridApi = params.api;
    }
  }

  gridOptionsDetail = {
    columnDefs: this.detailColumnDefs,
    rowData: this.rowDetailFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.detailGridApi = params.api;
    }
  }

  futureGridOptions= {
    columnDefs: this.futureColumnDefs,
    rowData: this.rowFutureFilteredData,
    animateRows: true,
    onGridReady: (params: any) => {
      this.futureGridApi = params.api;
    }
  }

  getFutureStocks(){
    this.futureGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),true).subscribe((resp) => {
      this.rowFutureData = resp;
      this.rowFutureFilteredData = resp;
      this.futureGridApi.showLoadingOverlay();
    })
  }
  getStocks() {
    this.gridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false).subscribe((resp) => {
      this.rowData = resp;
      this.rowFilteredData = resp;
      this.gridApi.showLoadingOverlay();
    })
  }

  getMinStocks() {
    this.minGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false, true).subscribe((resp) => {
      this.minRowData = resp;
      this.minRowFilteredData = resp;
      this.minGridApi.showLoadingOverlay();
    })
  }

  getSlowMovingStocks() {
    
    this.slowGridApi.showLoadingOverlay();
    this.stockService.getProductByStock(!this.isShowSpecialAllow(),false, false, true).subscribe((resp) => {
      this.slowRowData = resp;
      this.slowRowFilteredData = resp;
      this.slowGridApi.showLoadingOverlay();
    })
  }
  onView(data: any) {
    this.detailGridApi.showLoadingOverlay();
    this.searchDetailText = "";
    this.stockService.getStocksByProductId(data.id,false).subscribe((response: any) => {
      this.rowDetailData = response;
      this.rowDetailFilteredData = response;
      this.detailGridApi.showLoadingOverlay();
    });
  }
  activateData: any;
  onActivateModel(data: any) {
    this.activateData = data;
    const myModal = new bootstrap.Modal(document.getElementById('activateModal'));
    myModal.show();
  }
  onActivate() {
    let data = this.activateData;
    this.stockService.updateStatus(data.name, !data.isActive).subscribe((resp) => {
      this.getStocks();
      this.notifier.notify('success', 'Status Updated Successfully!');
    }, (error) => {
      this.notifier.notify('error', 'Status Update Failed!');
    });
  }
  handleFutureChange(){
    const searchPipe = new SearchPipe();
    this.rowFutureFilteredData = searchPipe.transform(this.rowFutureData, this.searchFutureText, ['name']);
  
  }
  handleDataDetailChange() {
    const searchPipe = new SearchPipe();
    this.rowDetailFilteredData = searchPipe.transform(this.rowDetailData, this.searchDetailText, ['productName', 'soNumber', 'stockType', 'level', 'rack']);
  }
  handleMinDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.minRowFilteredData = searchPipe.transform(this.minRowData, this.searchMinText, ['name']);
 
  }
  handleSlowDataDetailChange(){
    const searchPipe = new SearchPipe();
    this.slowRowFilteredData = searchPipe.transform(this.slowRowData, this.searchSlowText, ['name']);
 
  }
  handleDataChange() {
    const searchPipe = new SearchPipe();
    this.rowFilteredData = searchPipe.transform(this.rowData, this.searchText, ['name']);
  }
  isViewAllowed() {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name}` == `Detail-Stock`) != null;
  }
  onCellValueChanged(record: any) {
    this.stockService.updateAlertCount(record.data.name, record.data.minQuantityAlert).subscribe((resp) => {
      this.notifier.notify('success', 'Min. Alert Count Updated Successfully!');

    }, (error) => {
      this.notifier.notify('error', 'Min. Alert Count Update Failed!');
    });
  }

  isShowSpecialAllow() {
    var permission = localStorage.getItem('permission') ?? '[]';
    var jsonPermission = JSON.parse(permission);
    return jsonPermission.find((x: any) => `${x.permission.name}-${x.permission.module.name}` == `Special-Stock`) != null;
  }
}
